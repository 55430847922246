import React from 'react'
import { useDispatch } from 'react-redux'
import {
  clearIdentityCheck,
  updateRiskData,
} from 'containers/RiskDataCollectionPage/actions'
import {
  updatePreviousVehicle,
  updateVehicleValue,
} from 'containers/Vehicle/actions'
import Occupation from 'components/Occupation'
import { useOfMotorcycleOptions } from 'components/UseOfVehicle/constants'
import { useFormikContext } from 'formik'
import Select from 'components/@common/Select'
import Address from 'components/Address'
import FormField from 'components/@common/FormField'
import { VEHICLE_OWNERS } from 'components/VehicleOwner/constants'
import { motorcycleOvernightParkingOptions } from 'components/MotorcycleOvernightParking/constants'
import { motorcycleExperienceOptions } from 'components/MotorcycleRidingExperience/constants'
import { motorcycleEntitlementOptions } from 'components/MotorcycleEntitlement/constants'
import { motorcycleReasonOptions } from 'components/ReasonForCover/constants'
import PreviousRiddenMotorcycle from 'components/PreviouslyRiddenMotorcycle'
import {
  DateOfBirth,
  FirstName,
  LicenceType,
  Surname,
  Title,
  VehicleValue,
} from '../@CommonFields'
import { MotorcycleFormData } from './MotorcycleForm.types'

const MotorcycleForm = () => {
  const dispatch = useDispatch()
  const { values, setFieldTouched, setFieldValue, setFieldError } =
    useFormikContext<MotorcycleFormData>()
  const {
    dateOfBirth: dateOfBirthValue,
    ridingExperience: ridingExperienceValue,
  } = values

  const handleSelect = (name: string, value: string, label: string) => {
    const capitalisedName = name.charAt(0).toUpperCase() + name.slice(1)
    switch (capitalisedName) {
      case 'LicenceType':
        if (value === 'ProvisionalUkLicence') {
          return dispatch(
            updateRiskData({
              [capitalisedName]: {
                value,
                description: label,
              },
              Learner: true,
            }),
          )
        }
        dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
            Learner: false,
          }),
        )
        return dispatch(clearIdentityCheck())
      case 'OvernightParking':
        return dispatch(
          updateRiskData({
            MotorcycleOvernightParking: {
              value,
              description: label,
            },
          }),
        )
      case 'RidingExperience':
        setFieldTouched(
          'previousRiddenVehicle',
          value === 'HasNotRiddenOnTheRoadInTheLastTwoYears',
        )
        setFieldValue('previousRiddenVehicle', '')
        if (value === 'HasNotRiddenOnTheRoadInTheLastTwoYears') {
          setFieldError('previousRiddenVehicle', undefined)
          return dispatch(
            updatePreviousVehicle({
              RegistrationNumber: null,
              VehicleId: null,
              Make: null,
              Model: null,
            }),
          )
        }
        return dispatch(
          updateRiskData({
            MotorcycleRidingExperience: {
              value,
              description: label,
            },
          }),
        )
      case 'ReasonForPurchase':
        return dispatch(
          updateRiskData({
            MotorcycleReasonForTempcoverPurchase: {
              value,
              description: label,
            },
          }),
        )
      case 'UseOfVehicle':
        return dispatch(
          updateRiskData({
            MotorcycleUseOfVehicle: {
              value,
              description: label,
            },
            UseOfVehicle: {
              value,
              description: label,
            },
          }),
        )
      default:
        return dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
    }
  }

  return (
    <>
      <LicenceType onSelect={handleSelect} />
      <>
        <Title onSelect={handleSelect} />
        <FirstName />
        <Surname />
        <FormField label='Occupation' name='occupation'>
          <Occupation />
        </FormField>
        <DateOfBirth />
        <Address />
        <FormField
          name='useOfVehicle'
          label='Use of vehicle'
          isValidIcon={false}
        >
          <Select
            id='UseOfVehicle'
            name='useOfVehicle'
            onChange={handleSelect}
            options={useOfMotorcycleOptions}
            placeholder='Please select an option'
          />
        </FormField>
        <FormField
          name='ownerOfVehicle'
          label='Vehicle owner'
          isValidIcon={false}
        >
          <Select
            id='OwnerOfVehicle'
            name='ownerOfVehicle'
            onChange={handleSelect}
            options={VEHICLE_OWNERS}
            placeholder='Select vehicle owner'
          />
        </FormField>
        <FormField
          name='overnightParking'
          label='Overnight parking location'
          isValidIcon={false}
        >
          <Select
            id='OvernightParking'
            name='overnightParking'
            onChange={handleSelect}
            options={motorcycleOvernightParkingOptions}
            placeholder='Select motorbike overnight parking'
          />
        </FormField>
        <FormField
          name='ridingExperience'
          label='Motorbike riding experience'
          isValidIcon={false}
        >
          <Select
            id='RidingExperience'
            name='ridingExperience'
            onChange={handleSelect}
            options={motorcycleExperienceOptions}
            placeholder='Select motorbike riding experience'
          />
        </FormField>
        {ridingExperienceValue &&
          ridingExperienceValue.includes(
            'HasRiddenOnTheRoadInTheLastTwoYears',
          ) && <PreviousRiddenMotorcycle />}
        <FormField
          name='motorcycleEntitlement'
          label='Motorbike entitlement'
          isValidIcon={false}
        >
          <Select
            id='MotorcycleEntitlement'
            name='motorcycleEntitlement'
            onChange={handleSelect}
            options={motorcycleEntitlementOptions}
            placeholder='Please select'
          />
        </FormField>
        <FormField
          name='reasonForPurchase'
          label='Reason for cover'
          isValidIcon={false}
        >
          <Select
            id='ReasonForPurchase'
            name='reasonForPurchase'
            onChange={handleSelect}
            options={motorcycleReasonOptions}
            placeholder='Select reason for cover'
          />
        </FormField>
      </>
    </>
  )
}

export default MotorcycleForm
