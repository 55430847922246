import FormField from 'components/@common/FormField'
import Select from 'components/@common/Select'
import React from 'react'
import { SelectType } from '../types'
import { RESIDENCY_TYPES } from './ResidencyType.constants'

interface Props extends SelectType {}

const ResidencyType: React.FC<Props> = ({ onSelect }) => (
  <FormField name='residencyType' label='Residency' isValidIcon={false}>
    <Select
      id='ResidencyType'
      onChange={onSelect}
      name='residencyType'
      options={RESIDENCY_TYPES}
      placeholder='Please select your residency type'
    />
  </FormField>
)

export default ResidencyType
