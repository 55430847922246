export const motorcycleExperienceOptions = [
  {
    id: 2,
    value: 'HasRiddenOnTheRoadInTheLastTwoYears',
    description: 'I have ridden on the road in the last 2 years',
  },
  {
    id: 3,
    value: 'HasNotRiddenOnTheRoadInTheLastTwoYears',
    description: 'I have not ridden on the road in the last 2 years',
  },
]
