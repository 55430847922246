import FormField from 'components/@common/FormField'
import Select from 'components/@common/Select'
import { InitialStateType } from 'initialState'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SelectType } from '../types'

interface Props extends SelectType {}

const ReasonForPurchase: React.FC<Props> = ({ onSelect }) => {
  const reasonForPurchaseOptions = useSelector(
    (state: InitialStateType) =>
      state.driverDetailsFormFields.reasonForTempcoverPurchaseOptions,
  )

  const mappedReasonoptions = useMemo(
    () =>
      reasonForPurchaseOptions.map((option) => ({
        description: option.Description,
        displayorder: option.DisplayOrder,
        value: option.Value,
      })),
    [reasonForPurchaseOptions],
  )

  return (
    <FormField
      name='reasonForPurchase'
      label='Reason for cover'
      isValidIcon={false}
    >
      <Select
        id='ReasonForPurchase'
        name='reasonForPurchase'
        onChange={onSelect}
        options={mappedReasonoptions}
        placeholder='Please select your reason for cover'
      />
    </FormField>
  )
}

export default ReasonForPurchase
