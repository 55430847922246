import React from 'react'
import { Element } from 'react-scroll'
import { Grid } from 'unsemantic'
import { Option } from '../../types/global'
import FormField from '../@common/FormField'
import Select from '../@common/Select'
import { Message } from '../Message'
import PostcodeLookup from '../PostcodeLookup'

interface Props {
  error: boolean
  onChange: (value: string) => void
  onBlur: () => void
  onClick: () => void
  onSelect: (name: string, value: string, label: string) => void
  options: Option[]
  isLoading: boolean
}

const Address: React.FC<Props> = ({
  error,
  onChange,
  onClick,
  onBlur,
  onSelect,
  options,
  isLoading,
}) => (
  <>
    <Grid desktop='100' parent>
      <PostcodeLookup
        isLoading={isLoading}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        options={options}
      />

      <Element name='AddressSelect'>
        {options?.length > 0 && (
          <Grid desktop='100' tablet='100' mobile='100' parent>
            <FormField label='Address' name='addressKey' isValidIcon={false}>
              <Select
                id='AddressSelect'
                onChange={onSelect}
                name='addressKey'
                options={options}
                placeholder='Please select your address'
              />
            </FormField>
          </Grid>
        )}
      </Element>

      <Element name='AddressError'>
        {error && (
          <Grid desktop='100' tablet='100' mobile='100' parent>
            <Message
              type='error'
              heading='Address not found'
              content="We couldn't find an address at that postcode, please search again."
            />
          </Grid>
        )}
      </Element>
    </Grid>
  </>
)

export default Address
