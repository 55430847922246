/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import ContinueButton from 'components/ContinueButton'
import {
  useAddons,
  useAffiliate,
  useDataLayer,
  useLegalDeclaration,
  usePrice,
  useRiskData,
  useScheme,
} from 'hooks'
import { InitialStateType } from 'initialState'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid } from 'unsemantic'
import useOptimizelyExperiment from 'hooks/useOptimizelyExperiment'
import { ReactSDKClient, withOptimizely } from '@optimizely/react-sdk'
import { UserInfo } from '@optimizely/react-sdk/dist/utils'
import underwriterApi from '../../api/underwriterApi'
import { WhiteLabelledLink } from '../../components/common/A'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { H2 } from '../../components/common/H2'
import { H3 } from '../../components/common/H3'
import { DeclarationModal } from '../../components/DeclarationModal'
import config from '../../config'
import { QUOTE_URL } from '../../services/constants'
import { updateStep } from '../App/actions'
import { requestLegalDeclarationSuccess } from '../LegalDeclarationModal/actions'
import {
  LegalDeclarationHeader,
  LegalDeclarationParagraph,
  LegalDeclarationWrapper,
} from './LegalDeclarationPage.styles'
import { LegalDeclarationPageWrapper } from './LegalDeclarationPageWrapper'
import { ShortDeclaration } from './ShortDeclaration'
import { LegalDeclarationAgreeAndContinueText } from './styles'

interface LegalDeclarationPageProps {
  optimizely: ReactSDKClient | null
}

const LegalDeclarationPage: React.FC<LegalDeclarationPageProps> = ({
  optimizely,
}) => {
  const shouldUseSplitAddonsPage = useOptimizelyExperiment(
    optimizely,
    'addons_page',
    'page_enabled',
  )

  const [variant] = useState<string>('0')
  const [showLegalDec, setLegalDecOpen] = useState<boolean>(false)
  const [showOccupationDec, setOccupationDecOpen] = useState<boolean>(false)

  const [wordingLoaded, setWordingLoaded] = useState(false)
  const { availableAddons } = useAddons()

  const dispatch = useDispatch()
  const { legalDeclaration } = useLegalDeclaration()
  const { price } = usePrice()
  const { riskData } = useRiskData()
  const { scheme, isFirstLearner, isImpounded } = useScheme()
  const { affiliate } = useAffiliate()
  const { excess, breakdown, legalexpenses, gapinsurance } = useAddons()
  const history = useHistory()

  const legalDeclarationButtonDisabled = useSelector(
    (state: InitialStateType) => state.formButtons.legalDeclaration.disabled,
  )

  const queryString: string = useSelector(
    (state: InitialStateType) => state.queryString,
  )

  const myLicenceStatus = useSelector(
    (state: InitialStateType) => state.myLicence,
  )

  const { pushQuoteReferenceNumber } = useDataLayer()

  const handleContinueClick = () => {
    const existingUserId = localStorage.getItem('userId')
    const updatedUser: UserInfo = {
      id: existingUserId,
      attributes: {
        legalDeclarationPageClick: true,
        isTempcoverDirectCustomer: !queryString.includes('ref='),
      },
    }
    optimizely?.onReady().then(() => {
      optimizely?.setUser(updatedUser)
    })

    const url =
      shouldUseSplitAddonsPage && availableAddons && availableAddons.length
        ? `${QUOTE_URL}/addons${queryString}`
        : `${QUOTE_URL}/payment${queryString}`

    history.push(url)
  }

  const className = useMemo(() => {
    if (myLicenceStatus.DisabledBySetting) {
      return `uw_${price.Underwriter}`
    }
    if (myLicenceStatus.Enabled) {
      return `ab_mylicence_on uw_${price.Underwriter}`
    }
    return `ab_mylicence_off uw_${price.Underwriter}`
  }, [myLicenceStatus, price])

  useEffect(() => {
    dispatch(updateStep(2))
    pushQuoteReferenceNumber()
    underwriterApi
      .getLegalDeclaration(
        `/api/affiliates/${affiliate.Ref || 'tempcover'}/quotes/${
          riskData.QuoteId
        }/schemes/${scheme}/underwriters/${
          price.UnderwriterId
        }/allDeclarations`,
      )
      .then((result) => {
        dispatch(requestLegalDeclarationSuccess(result.data))
        setWordingLoaded(true)
      })
    if (document.getElementById('DisallowedOccupationListItem')) {
      const disallowedOccupationsList = document.getElementById(
        'DisallowedOccupationListItem',
      )
      const disallowedOccupationsText = document.createTextNode(
        'Not be in one of the following occupations: ',
      )
      const disallowedOccupationsLink = document.createElement('p')
      disallowedOccupationsLink.innerHTML = 'View'
      disallowedOccupationsLink.setAttribute('id', 'DisallowedOccupationsLink')
      disallowedOccupationsLink.onclick = () => {
        setOccupationDecOpen(true)
      }
      if (disallowedOccupationsList) {
        disallowedOccupationsList.appendChild(disallowedOccupationsText)
        disallowedOccupationsList.appendChild(disallowedOccupationsLink)
      }
    }
  }, [])

  const ShortDeclarationAB = () => {
    if (variant === '1') {
      return (
        <LegalDeclarationWrapper>
          <ShortDeclaration html={legalDeclaration.ShortText} />
        </LegalDeclarationWrapper>
      )
    }
    return <ShortDeclaration html={legalDeclaration.ShortText} />
  }

  const HeaderComponent: React.FC = ({ children }) => {
    if (variant === '1') {
      return (
        <LegalDeclarationHeader id='legaldeclaration-header'>
          {children}
        </LegalDeclarationHeader>
      )
    }
    return <H3 id='legaldeclaration-header'>{children}</H3>
  }

  const ParagraphComponent: React.FC = ({ children }) => {
    if (variant === '1') {
      return <LegalDeclarationParagraph>{children}</LegalDeclarationParagraph>
    }
    return (
      <LegalDeclarationAgreeAndContinueText>
        {children}
      </LegalDeclarationAgreeAndContinueText>
    )
  }

  const Wrapper: React.FC = ({ children }) => {
    if (variant === '1') {
      return <>{children}</>
    }
    return <LegalDeclarationPageWrapper>{children}</LegalDeclarationPageWrapper>
  }

  const CoverTypeText = useMemo(() => {
    if (riskData.Impounded) {
      return (
        <>
          <HeaderComponent>
            {price.CoverType || 'Fully comprehensive'} cover
          </HeaderComponent>
          <HeaderComponent>
            The policyholder <strong>MUST</strong> be the owner and registered
            keeper of the vehicle being insured.{' '}
          </HeaderComponent>
        </>
      )
    }
    if (isFirstLearner) {
      return (
        <HeaderComponent>
          <strong>{price.CoverType}</strong> cover with &pound;
          {price.CompulsoryExcess.toFixed(2)} Accidental Damage, Malicious
          Damage and Fire excess
          <br />
          £1000.00 Theft excess
        </HeaderComponent>
      )
    }

    const addons: String[] = []

    if (breakdown.selected) addons.push('RAC UK Breakdown Cover')

    if (legalexpenses.selected) addons.push('Legal Expenses Cover')
    if (gapinsurance.selected) addons.push('Free 30 day GAP Insurance')

    return (
      <HeaderComponent>
        {`${price.CoverType || 'Fully Comprehensive'} `}
        {addons.length ? `with ${addons.join(', ')} and ` : 'cover with '}
        {`£${price.CompulsoryExcess.toFixed(2)} excess`}
        {excess.selected && (
          <>
            <br />
            Excess Reduction Cover £{excess.coverLevel.toFixed(2)}
          </>
        )}
      </HeaderComponent>
    )
  }, [
    riskData.Impounded,
    isFirstLearner,
    breakdown.selected,
    legalexpenses.selected,
    gapinsurance.selected,
    HeaderComponent,
    price.CoverType,
    price.CompulsoryExcess,
    excess.selected,
    excess.coverLevel,
  ])

  return (
    <GridContainerWrapper>
      <Wrapper>
        <H2>Your policy is</H2>
        {CoverTypeText}

        <H2>Just to check...</H2>
        <Grid desktop='100'>
          <ShortDeclarationAB />
        </Grid>
        <Grid desktop='100' parent>
          <Grid desktop='50' mobile='100' tablet='50'>
            <WhiteLabelledLink
              id='FullDeclaration'
              onClick={() => setLegalDecOpen(true)}
            >
              Read full declaration here
            </WhiteLabelledLink>
          </Grid>
          <Grid desktop='50' mobile='100' tablet='50'>
            <WhiteLabelledLink
              id='TempcoverContract'
              href={`${config.WEB_URL}/${legalDeclaration.TempcoverContractUrl}`}
              target='_blank'
            >
              Download your Tempcover Contract
            </WhiteLabelledLink>
          </Grid>
        </Grid>
        <Grid desktop='100'>
          <Grid desktop='50' mobile='100' tablet='50'>
            <WhiteLabelledLink
              id='PolicyWording'
              href={`${config.WEB_URL}/${legalDeclaration.PolicyWordingUrl}`}
              target='_blank'
            >
              Download policy wording here
            </WhiteLabelledLink>
          </Grid>
          <Grid desktop='50' mobile='100' tablet='50'>
            <WhiteLabelledLink
              id='ProductInformation'
              href={`${config.WEB_URL}/${legalDeclaration.InsuranceProductInformationUrl}`}
              target='_blank'
            >
              Download insurance product information here
            </WhiteLabelledLink>
          </Grid>
        </Grid>
        <Grid desktop='100'>
          <ParagraphComponent>
            By proceeding, you confirm that you have read, can meet, and have
            understood the terms and conditions set by{' '}
            {price.UnderwriterDisplayName} as laid out in the full declaration.{' '}
            {isImpounded
              ? 'On this basis, this policy will meet the demands and needs of someone who needs to insure their vehicle in order to release it from a Police Impound.'
              : 'On this basis, this policy will meet the demands and needs of someone who needs to insure a vehicle for a short period.'}
          </ParagraphComponent>
        </Grid>
        <ContinueButton
          type='submit'
          disabled={!wordingLoaded}
          id='TeslaSubmitDeclarationNextButton'
          fullWidth
          onClick={handleContinueClick}
          data-email={riskData.EmailAddress}
          className={className}
        >
          {legalDeclarationButtonDisabled
            ? 'Please wait'
            : 'Agree and Continue'}
          <ButtonSpinner disabled={legalDeclarationButtonDisabled} />
        </ContinueButton>
      </Wrapper>
      <DeclarationModal
        text={legalDeclaration.FullText}
        id='declarationModal'
        title='Full Declaration'
        hideFullDeclaration={() => setLegalDecOpen(false)}
        isVisible={showLegalDec}
      />
      <DeclarationModal
        text={legalDeclaration.DisallowedOccupations}
        id='disallowedOccupationsModal'
        title='Unacceptable Occupations'
        hideFullDeclaration={() => setOccupationDecOpen(false)}
        isVisible={showOccupationDec}
      />
    </GridContainerWrapper>
  )
}

export default withOptimizely(LegalDeclarationPage)
