import FormField from 'components/@common/FormField'
import TextField from 'components/@common/TextField'
import React from 'react'

const FirstName: React.FC = () => (
  <FormField name='firstName' label='First name'>
    <TextField id='Forename' name='firstName' placeholder='Hi, my name is...' />
  </FormField>
)

export default FirstName
