import FormField from 'components/@common/FormField'
import Select from 'components/@common/Select'
import Address from 'components/Address'
import Occupation from 'components/Occupation'
import config from 'config'
import {
  clearIdentityCheck,
  updateRiskData,
} from 'containers/RiskDataCollectionPage/actions'
import { updateVehicleValue } from 'containers/Vehicle/actions'
import { useFormikContext } from 'formik'
import { useABExperiment, useScheme } from 'hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  DateOfBirth,
  FirstName,
  LicenceHeldDuration,
  LicenceType,
  ReasonForPurchase,
  ReasonForPurchaseOtherText,
  ResidencyType,
  Surname,
  Title,
  UKResidencyDuration,
  VehicleValue,
} from '../@CommonFields'
import { ReasonForPurchaseEnum } from '../@CommonFields/ReasonForPurchase/ReasonForPurchaseEnumeration'
import {
  BODY_TYPE_OPTIONS,
  USE_OF_VEHICLE_OPTIONS,
} from './CommercialVehicleForm.constants'
import { CommercialVehicleFormData } from './CommercialVehicleForm.types'

const CommercialVehicleForm = () => {
  const dispatch = useDispatch()
  const { isImpounded } = useScheme()

  const { values, setFieldValue, errors } =
    useFormikContext<CommercialVehicleFormData>()

  const {
    dateOfBirth: dateOfBirthValue,
    residencyType: residencyTypeValue,
    licenceType: licenceTypeValue,
    useOfVehicle: useOfVehicleValue,
    reasonForPurchase: reasonForPurchaseValue,
  } = values

  // TODO: Revisit this, at the minute we are just reusing logic
  //  from before to store descriptions in redux.
  const handleSelect = (name: string, value: string, label: string) => {
    // We need to capitalise first letter, as in state they are using capitals at the start
    const capitalisedName = name.charAt(0).toUpperCase() + name.slice(1)
    switch (capitalisedName) {
      case 'LicenceType':
        dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
        return dispatch(clearIdentityCheck())
      case 'ResidencyType':
        if (value === 'PermanentUkResident') {
          setFieldValue('ukResidencyDuration', '')
          return dispatch(
            updateRiskData({
              Expat: false,
              UkResidencyDuration: {
                value: '',
                description: '',
              },
            }),
          )
        }
        return dispatch(
          updateRiskData({
            Expat: true,
          }),
        )

      default:
        return dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
    }
  }
  if (useOfVehicleValue === 'CourierOrHaulage') {
    return (
      <FormField name='useOfVehicle' label='Use of vehicle' isValidIcon={false}>
        <Select
          id='UseOfVehicle'
          onChange={handleSelect}
          name='useOfVehicle'
          options={USE_OF_VEHICLE_OPTIONS}
          placeholder='I am using my van for...'
        />
      </FormField>
    )
  }
  return (
    <>
      <LicenceType onSelect={handleSelect} />
      {(licenceTypeValue !== 'ProvisionalUkLicence' || isImpounded) && (
        <>
          <Title onSelect={handleSelect} />
          <FirstName />
          <Surname />
          {!isImpounded && (
            <>
              <ResidencyType onSelect={handleSelect} />
              {residencyTypeValue &&
                residencyTypeValue !== 'PermanentUkResident' && (
                  <UKResidencyDuration onSelect={handleSelect} />
                )}
            </>
          )}
          <DateOfBirth />
          <Address />
          {dateOfBirthValue && !errors.dateOfBirth && (
            <LicenceHeldDuration onSelect={handleSelect} />
          )}
          <FormField name='bodyType' label='Body type' isValidIcon={false}>
            <Select
              id='BodyType'
              name='bodyType'
              onChange={handleSelect}
              options={BODY_TYPE_OPTIONS}
              placeholder="My van's body type is..."
            />
          </FormField>
          {
            // If NOT impounded, show the following elements
            !isImpounded && (
              <>
                <FormField
                  name='useOfVehicle'
                  label='Use of vehicle'
                  isValidIcon={false}
                >
                  <Select
                    id='UseOfVehicle'
                    onChange={handleSelect}
                    name='useOfVehicle'
                    options={USE_OF_VEHICLE_OPTIONS}
                    placeholder='I am using my van for...'
                  />
                </FormField>
                <FormField label='Occupation' name='occupation'>
                  <Occupation />
                </FormField>
                <ReasonForPurchase onSelect={handleSelect} />
                {reasonForPurchaseValue &&
                  reasonForPurchaseValue === ReasonForPurchaseEnum.other && (
                    <ReasonForPurchaseOtherText />
                  )}
              </>
            )
          }
        </>
      )}
    </>
  )
}

export default CommercialVehicleForm
