import FormField from 'components/@common/FormField'
import Select from 'components/@common/Select'
import React from 'react'
import { SelectType } from '../types'
import { TITLE_OPTIONS } from './Title.constants'

interface Props extends SelectType {}

const Title: React.FC<Props> = ({ onSelect }) => (
  <FormField name='title' label='Title' isValidIcon={false}>
    <Select
      id='Title'
      name='title'
      onChange={onSelect}
      options={TITLE_OPTIONS}
      placeholder='Please select your title'
    />
  </FormField>
)

export default Title
