import FormField from 'components/@common/FormField'
import Select from 'components/@common/Select'
import React from 'react'
import { SelectType } from '../types'
import { RESIDENCY_DURATION_TYPES } from './UKResidencyDuration.constants'

interface Props extends SelectType {}

const UKResidencyDuration: React.FC<Props> = ({ onSelect }) => (
  <FormField
    name='ukResidencyDuration'
    label='Length of previous UK Residency'
    isValidIcon={false}
  >
    <Select
      id='UkResidencyDuration'
      onChange={onSelect}
      name='ukResidencyDuration'
      options={RESIDENCY_DURATION_TYPES}
      placeholder='Please select a residency duration'
    />
  </FormField>
)

export default UKResidencyDuration
