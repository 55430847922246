import FormField from 'components/@common/FormField'
import Select from 'components/@common/Select'
import Address from 'components/Address'
import Occupation from 'components/Occupation'
import {
  clearIdentityCheck,
  updateRiskData,
} from 'containers/RiskDataCollectionPage/actions'
import { updateVehicleValue } from 'containers/Vehicle/actions'
import { useFormikContext } from 'formik'
import { useScheme } from 'hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  DateOfBirth,
  FirstName,
  LicenceHeldDuration,
  LicenceType,
  ResidencyType,
  Surname,
  Title,
  UKResidencyDuration,
  VehicleValue,
} from '../@CommonFields'
import { BODY_TYPE_OPTIONS } from './CourierForm.constants'
import { CourierFormData } from './CourierForm.types'

const CourierForm = () => {
  const dispatch = useDispatch()
  const { isImpounded } = useScheme()

  const { values, setFieldValue, errors } = useFormikContext<CourierFormData>()

  const {
    dateOfBirth: dateOfBirthValue,
    residencyType: residencyTypeValue,
    licenceType: licenceTypeValue,
  } = values

  // TODO: Revisit this, at the minute we are just reusing logic
  //  from before to store descriptions in redux.
  const handleSelect = (name: string, value: string, label: string) => {
    // We need to capitalise first letter, as in state they are using capitals at the start
    const capitalisedName = name.charAt(0).toUpperCase() + name.slice(1)
    switch (capitalisedName) {
      case 'LicenceType':
        dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
        return dispatch(clearIdentityCheck())
      case 'ResidencyType':
        if (value === 'PermanentUkResident') {
          setFieldValue('ukResidencyDuration', '')
          return dispatch(
            updateRiskData({
              Expat: false,
              UkResidencyDuration: {
                value: '',
                description: '',
              },
            }),
          )
        }
        return dispatch(
          updateRiskData({
            Expat: true,
          }),
        )

      default:
        return dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
    }
  }

  return (
    <>
      <LicenceType onSelect={handleSelect} />
      {licenceTypeValue !== 'ProvisionalUkLicence' && (
        <>
          <Title onSelect={handleSelect} />
          <FirstName />
          <Surname />
          {!isImpounded && (
            <>
              <ResidencyType onSelect={handleSelect} />
              {residencyTypeValue &&
                residencyTypeValue !== 'PermanentUkResident' && (
                  <UKResidencyDuration onSelect={handleSelect} />
                )}
            </>
          )}
          <DateOfBirth />
          <Address />
          {dateOfBirthValue && !errors.dateOfBirth && (
            <LicenceHeldDuration onSelect={handleSelect} />
          )}
          <FormField name='bodyType' label='Body type' isValidIcon={false}>
            <Select
              id='BodyType'
              name='bodyType'
              onChange={handleSelect}
              options={BODY_TYPE_OPTIONS}
              placeholder="My van's body type is..."
            />
          </FormField>
          <FormField label='Occupation' name='occupation'>
            <Occupation />
          </FormField>
        </>
      )}
    </>
  )
}

export default CourierForm
